import { isEmpty } from "../../../utils";
import { ApplicationSettings } from "./ApplicationSettings";

export default class YandexMetrikaApplicationSettings extends ApplicationSettings {
    constructor(s?: Partial<YandexMetrikaApplicationSettings>) {
        super();
        if (s) this.update(s);
    }

    public name: string;
    public accessToken: string;
    public counterId: string;

    get isValid() {
        return !isEmpty(this.accessToken) && !isEmpty(this.counterId);
    }

    get isAccessTokenMasked() {
        return this.accessToken.slice(0, 3) === '***';
    }

    update(entity: Partial<YandexMetrikaApplicationSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    static fromJson(json: any): YandexMetrikaApplicationSettings {
        return new YandexMetrikaApplicationSettings({ ...json });
    }
}