import { Entity } from '../..';
import { observable, computed } from 'mobx';
import { PaymentGatewayType } from './PaymentGatewayType';
import { Currency } from '../../../enums';
import YandexCheckoutSettings from './Settings/YandexCheckoutSettings';
import StripeSettings from './Settings/StripeSettings';
import RoboKassaSettings from './Settings/RoboKassaSettings';
import PayPalSettings from './Settings/PayPalSettings';
import CloudPaymentsSettings from './Settings/CloudPaymentsSettings';
import PaymentGatewayStats from './PaymentGatewayStats';
import MidtransSettings from './Settings/MidtransSettings';
import TinkoffSettings from './Settings/TinkoffSettings';
import ProdamusSettings from './Settings/ProdamusSettings';
import SberbankSettings from './Settings/SberbankSettings';
import FondySettings from './Settings/FondySettings';
import BePaidSettings from './Settings/BePaidSettings';
import SquareUpSettings from './Settings/SquareUpSettings';
import SmartGlocalSettings from './Settings/SmartGlocalSettings';
import BitPaySettings from './Settings/BitPaySettings';
import EcPaySettings from './Settings/EcPaySettings';
import TinkoffInstallmentSettings from './Settings/TinkoffInstallmentSettings';
import MercadopagoSettings from './Settings/MercadopagoSettings';
import CyberSourceSettings from './Settings/CyberSourceSettings';
import { ISerializable, Updatable } from '../../Entity';
import WayForPaySettings from './Settings/WayForPaySettings';
import MobbexSettings from './Settings/MobbexSettings';
import ProntoPagaSettings from './Settings/ProntoPagaSettings';


export default class PaymentGateway extends Entity implements ISerializable {
    constructor(gateway?: Partial<PaymentGateway>) {
        super(gateway);
        if (gateway) this.update(gateway);
    }

    @observable name: string;
    @observable description: string;
    @observable paymentButtonText: string;
    @observable type: PaymentGatewayType;
    @observable settings: string;
    @observable isPrimary: boolean;
    @observable showOnSite: boolean;
    @observable saveCardEnabled: boolean;
    @observable currency: Currency | null;
    stats: PaymentGatewayStats[];

    get hasNoPayments() {
        return this.stats.every(x => x.value == 0);
    }

    @computed get settingsJson(): any {
        return this.settings ? JSON.parse(this.settings) : null;
    }

    // TODO use inline ctor like bitPaySettings
    get yandexCheckoutSettings(): YandexCheckoutSettings {
        const settings = new YandexCheckoutSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get stripeSettings(): StripeSettings {
        const settings = new StripeSettings({ isWidgetModeEnabled: true });
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get roboKassaSettings(): RoboKassaSettings {
        const settings = new RoboKassaSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get payPalSettings(): PayPalSettings {
        const settings = new PayPalSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get cloudPaymentsSettings(): CloudPaymentsSettings {
        const settings = new CloudPaymentsSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get midtransSettings(): MidtransSettings {
        const settings = new MidtransSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get tinkoffSettings(): TinkoffSettings {
        const settings = new TinkoffSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get prodamusSettings(): ProdamusSettings {
        const settings = new ProdamusSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get sberbankSettings(): SberbankSettings {
        const settings = new SberbankSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get fondySettings(): FondySettings {
        const settings = new FondySettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get bePaidSettings(): BePaidSettings {
        const settings = new BePaidSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get squareUpSettings(): SquareUpSettings {
        const settings = new SquareUpSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get smartGlocalSettings(): SmartGlocalSettings {
        const settings = new SmartGlocalSettings();
        if (this.settings) settings.update(this.settingsJson);
        return settings;
    }

    get bitPaySettings() {
        return new BitPaySettings(this.settingsJson);
    }

    get ecPaySettings() {
        return new EcPaySettings(this.settingsJson);
    }

    get tinkoffInstallmentSettings() {
        return new TinkoffInstallmentSettings(this.settingsJson);
    }

    get mercadopagoSettings() {
        return new MercadopagoSettings(this.settingsJson);
    }

    get cyberSourceSettings() {
        return new CyberSourceSettings(this.settingsJson);
    }

    get wayForPaySettings() {
        return new WayForPaySettings(this.settingsJson);
    }

    get mobbexSettings() {
        return new MobbexSettings(this.settingsJson);
    }

    get prontoPagaSettings() {
        return new ProntoPagaSettings(this.settingsJson);
    }

    update(product: Partial<PaymentGateway>) {
        super.update(product);
    }

    toJson() {
        throw new Error('not implemented');
    }

    clone() {
        return new PaymentGateway({
            ...this,
            stats: this.stats?.map(x => x.clone())
        });
    }

    static fromJson(json: any): PaymentGateway {
        return new PaymentGateway({
            ...json,
            stats: json.stats ? json.stats.map(PaymentGatewayStats.fromJson) : []
        });
    }
}