import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styles from './TruncatedText.module.scss';
import { combineClasses } from '../../utils';
import { Context } from '../AccelProvider/AccelProvider';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useUpdateEffect } from '../hooks/useUpdateEffect';

type TruncatedTextProps = {
    defaultCollapsed?: boolean;
    mode?: 'text-clamp' | 'height';
    maxLines?: number;
    maxHeight?: number | string;
    className?: string;
    btnClassname?: string;
    title?: string;
    extra?: React.ReactNode;
    shadow?: boolean;
    shadowHeight?: number | string;
}
const TruncatedText: React.FC<TruncatedTextProps> = ({ defaultCollapsed, maxLines, maxHeight, className, btnClassname, extra, mode, shadow, shadowHeight, title, children }) => {
    const { loc } = useContext(Context);
    const el = useRef<any>(null);
    const [expandable, setExpandable] = useState(false);
    const [collapsed, setCollapsed] = useState(defaultCollapsed ?? true);

    const calcClampText = useCallback(() => {
        if (!el.current) return;
        setExpandable(el.current.scrollHeight > el.current.clientHeight);
    }, []);

    const toggle = useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed]);

    useUpdateEffect(() => {
        setCollapsed(true);
        // wait for collapse animation to complete
        const timeout = setTimeout(() => calcClampText(), 100);
        return () => clearTimeout(timeout);
    }, [children]);

    useLayoutEffect(() => {
        calcClampText();
    }, []);

    const toggleBtn = useMemo(() => {
        return <Button type='link' size='small' onClick={toggle} className={combineClasses('p-0 ', btnClassname)}>
            {collapsed
                ? <>
                    {loc.word('Global.expand', { default: 'Expand' })}
                    &nbsp;
                    <CaretDownOutlined />
                </>
                : <>
                    {loc.word('Global.collapse', { default: 'Collapse' })}
                    &nbsp;
                    <CaretUpOutlined />
                </>}
        </Button>;
    }, [collapsed, toggle, btnClassname]);

    return <div className={styles.truncated_text_box} data-collapsed={collapsed}>
        {mode == 'text-clamp'
            ? <span ref={el} title={title} className={combineClasses(styles.truncated__text, `line-clamp-${maxLines ?? 3} break-word`, className)}>{children}</span>
            : <div ref={el} title={title} style={{ maxHeight: collapsed ? maxHeight : 'unset' }} className='overflow-hidden'>
                {children}
            </div>}

        {(shadow === true && expandable && collapsed) && <div className={styles.truncated_text__shadow} style={{ height: shadowHeight }}></div>}
        <div className='flex align-center justify-between'>
            <span>{extra}</span>
            {expandable && toggleBtn}
        </div>
    </div>;
}
TruncatedText.defaultProps = {
    maxLines: 3,
    maxHeight: '150px',
    mode: 'text-clamp',
    shadow: true,
    shadowHeight: '60px'
}
export default TruncatedText;