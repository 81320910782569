import { observable, action, computed } from 'mobx';
import { Entity, AccelFile, SchoolProfileSettings, Timezone, SiteDomain, Admin, ChatChannelIntegration, Partnership, type Country, Gamification, Organization, Updatable } from '..';
import { ApplicationType } from '..';
import { Currency, Lang } from '../../enums';
import SchoolBilling from './SchoolBilling';
import SchoolMetadata from './SchoolMetadata';
import SchoolSettings from './SchoolSettings';
import moment from 'moment';
import { ISerializable } from '../Entity';

export default class School extends Entity {
    constructor(school?: Partial<School>) {
        super(school);
        if (school) this.update(school);
    }

    @observable name: string;
    @observable description: string;
    @observable number: number;
    @observable domain: string;
    @observable customDomain: string;
    @observable disableOpenRegistration: boolean;
    @observable isForumAvailable: boolean;
    @observable settings: SchoolSettings;
    @observable profileSettings: SchoolProfileSettings;
    @observable timezone: Timezone;
    @observable owner: Admin;
    @observable logo: AccelFile | null;
    @observable favicon: AccelFile | null;
    @observable showUnavailableStartedCourses: boolean;
    @observable isExternal: boolean;

    /**
     * @deprecated use owner instead
     */
    @observable ownerId: string;
    @observable jsHeadCode: string;
    @observable jsBodyCode: string;
    @observable supportChatEnabled: boolean;
    @observable supportConversationTitle: string;
    @observable recordInternalCalls: boolean;
    @observable recordCRMCalls: boolean;
    @observable lang: Lang;
    @observable createdDate: Date;
    @observable mainSiteDomain: SiteDomain | null;

    @observable phoneBalance: number;

    @observable currency: Currency;
    @observable billing: SchoolBilling;
    @observable metadata: SchoolMetadata;
    @observable country: Country;
    @observable appIntegrations: ApplicationType[];
    @observable chatChannelIntegrations: ChatChannelIntegration[];
    @observable allowCustomProductGatewaySettings: boolean;
    @observable allowCustomProductPaymentMethodsSettings: boolean;
    @observable organization: Organization;

    @observable partnership: Partnership | null;

    @observable gamification: Gamification | null;

    @observable migration: SchoolMigration | null = null;
    @observable migrationStatus: SchoolMigrationStatus;
    @computed get hasMigrationLock() {
        return this.migration?.isActive || this.migration?.isCompleted;
    }

    @computed get canUseBillingAddress() {
        return this.billing.currency == Currency.USD;
    }

    @computed get hasTgAppIntegration() {
        return this.appIntegrations.includes(ApplicationType.Telegram);
    }

    @computed get hasChatChannelIntegrations() {
        return this.chatChannelIntegrations.length > 0;
    }

    @action update(school: Partial<School>) {
        super.update(school);
    }

    @action setPromoCode(code: string) {
        this.metadata.billingPromoCode?.update({ code: code });
    }

    @computed get canMigrate() {
        return this.migrationStatus == SchoolMigrationStatus.Default
            || this.migrationStatus == SchoolMigrationStatus.Aborted
            || this.migrationStatus == SchoolMigrationStatus.Error;
    }

    clone(): School {
        return new School({
            ...this,
            settings: this.settings?.copy(),
            profileSettings: this.profileSettings.copy(),
            timezone: this.timezone.clone(),
            owner: this.owner?.clone(),
            logo: this.logo?.clone(),
            favicon: this.favicon?.clone(),
            createdDate: this.createdDate ? new Date(this.createdDate) : undefined,
            mainSiteDomain: this.mainSiteDomain?.clone(),
            billing: this.billing?.clone(),
            metadata: this.metadata?.clone(),
            chatChannelIntegrations: this.chatChannelIntegrations.map((c) => c.clone()),
            partnership: this.partnership?.clone(),
            organization: this.organization?.clone()
        });
    }

    static fromJson(json: any): School {
        return new School({
            ...json,
            timezone: json.timezone
                ? Timezone.fromJson(json.timezone)
                : Timezone.utc(),
            settings: json.settings ? SchoolSettings.fromJson(json.settings) : new SchoolSettings(),
            profileSettings: SchoolProfileSettings.fromJson(json.studentProfileSettings),
            logo: json.logo ? AccelFile.fromJson(json.logo) : null,
            favicon: json.favicon ? AccelFile.fromJson(json.favicon) : null,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            mainSiteDomain: json.mainSiteDomain ? SiteDomain.fromJson(json.mainSiteDomain) : null,
            billing: json.billing ? SchoolBilling.fromJson(json.billing) : undefined,
            metadata: json.metadata ? SchoolMetadata.fromJson(json.metadata) : undefined,
            country: json.country,
            owner: json.owner ? Admin.fromJson(json.owner) : undefined,
            appIntegrations: json.appIntegrations ?? [],
            chatChannelIntegrations: json.chatChannelIntegrations
                ? json.chatChannelIntegrations.map(ChatChannelIntegration.fromJson)
                : [],
            allowCustomProductGatewaySettings: json.allowCustomProductGatewaySettings ?? false,
            allowCustomProductPaymentMethodsSettings: json.allowCustomProductPaymentMethodsSettings ?? false,
            partnership: json.partnership ? Partnership.fromJson(json.partnership) : null,
            gamification: json.gamification ? Gamification.fromJson(json.gamification) : null,
            organization: json.organization ? Organization.fromJson(json.organization) : undefined,
            migration: json.migration ? SchoolMigration.fromJson(json.migration) : undefined
            // new SchoolMigration({
            //     status: SchoolMigrationStatus.Completed,
            //     progress: 0,
            //     scheduledDate: moment().add(1, 'day'),
            //     settings: {
            //         targetUrls: {
            //             admin: 'https://admin.accelonline.io',
            //             school: 'https://school.accelonline.io',
            //         }
            //     }
            // })
        });
    }
}

export enum SchoolMigrationStatus {
    Default = 'default',
    Pending = 'pending',
    InProgress = 'inProgress',
    Completed = 'completed',
    Aborted = 'aborted',
    Error = 'error'
}

export type UrlSettings = {
    chat?: string;
    site?: string;
    admin?: string;
    application?: string;
    keeper?: string;
    manager?: string;
    receiver?: string;
    school?: string;
    webinar?: string;
}

export type SchoolMigrationSettings = {
    sourceUrls: UrlSettings | null;
    targetUrls: UrlSettings | null;
}

export class SchoolMigration extends Entity implements ISerializable {

    constructor(schoolMigration?: Partial<SchoolMigration>) {
        super();
        if (schoolMigration) this.update(schoolMigration);
    }

    //@ts-ignore
    @observable school: School = undefined;
    @observable status: SchoolMigrationStatus = SchoolMigrationStatus.Default;
    @observable progress: number = 0;
    @observable durationInMinutes: number = 5;
    scheduledDate?: moment.Moment = undefined;
    settings?: SchoolMigrationSettings = undefined;
    error?: string = undefined;

    @computed get isScheduled() {
        return this.status == SchoolMigrationStatus.Default
            || this.status == SchoolMigrationStatus.Pending;
    }

    @computed get isActive() {
        return this.status == SchoolMigrationStatus.InProgress;
    }

    @computed get isCompleted() {
        return this.status == SchoolMigrationStatus.Completed
            || this.status == SchoolMigrationStatus.Aborted
            || this.status == SchoolMigrationStatus.Error;
    }

    update(schoolMigration: Partial<SchoolMigration>) {
        super.update(schoolMigration);
    }

    toJson() {
        return {
            ...this,
            schoolId: this.school?.id,
            school: undefined,
            scheduledDate: this.scheduledDate?.toISOString(),
            settingsJson: this.settings ? JSON.stringify(this.settings) : undefined,
        };
    }

    static fromJson(json: any): SchoolMigration {
        return new SchoolMigration({
            ...json,
            scheduledDate: json.scheduledDate ? moment(json.scheduledDate) : undefined,
            settings: json.settingsJson ? JSON.parse(json.settingsJson) : undefined,
        });
    }
}